import type { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";

const config = {
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./layouts/**/*.{js,ts,jsx,tsx}",
  ],
  future: {
    hoverOnlyWhenSupported: true,
  },
  safelist: [
    "text-black",
    "text-white",
    "text-red",
    "text-pink",
    "text-cyan",
    "text-cyan-light",
    "text-blue",
    "text-blue-light",
    "text-yellow",
    "text-orange",
    "text-stone-light",
    "text-stone-dark",
    "text-green",
    "bg-black",
    "bg-white",
    "bg-red",
    "bg-pink",
    "bg-cyan",
    "bg-cyan-light",
    "bg-blue",
    "bg-blue-light",
    "bg-orange",
    "bg-yellow",
    "bg-green",
    "bg-stone-light",
    "bg-stone-dark",
    "pull-double",
    "pull-single",
  ],
  darkMode: "class",
  theme: {
    extend: {
      lineHeight: {
        tighter: "1.125",
        tightest: "1.0625",
      },
      animation: {
        "spin-slow": "spin 8s linear infinite",
      },
      screens: {
        //	'sm': '640px',
        //	'md': '768px',
        //	'lg': '1024px',
        //	'xl': '1280px',
        //	'2xl': '1536px',
      },

      gridTemplateColumns: {
        24: "repeat(24, minmax(0, 1fr))",
      },
      gridColumn: {
        "span-13": "span 13 / span 13",
        "span-14": "span 14 / span 14",
        "span-15": "span 15 / span 15",
        "span-16": "span 16 / span 16",
        "span-17": "span 17 / span 17",
        "span-18": "span 18 / span 18",
        "span-19": "span 19 / span 19",
        "span-20": "span 20 / span 20",
        "span-21": "span 21 / span 21",
        "span-22": "span 22 / span 22",
        "span-23": "span 23 / span 23",
        "span-24": "span 24 / span 24",
      },
      gridColumnStart: {
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
      },
      typography: ({ theme }) => ({
        DEFAULT: {
          css: {
            "--tw-prose-bullets": theme("colors.current"),
            "--tw-prose-counters": theme("colors.current"),
            p: {
              color: theme("colors.current"),
            },
          },
        },
      }),
      transitionDuration: {
        0: "0ms",
        1200: "1200ms",
        1500: "1500ms",
        2000: "2000ms",
        10000: "10000ms",
      },
      transitionProperty: {
        fade: "opacity, transform",
      },
      maxWidth: ({ theme }) => ({
        xxxs: theme("width.44"),
        xxs: theme("width.72"),
      }),
      minHeight: {
        24: "6rem",
      },
      fontSize: {
        "10xl": [
          "10rem", // 160px
          {
            lineHeight: "1",
          },
        ],
      },
      colors: {
        orange: "#ef7000",
        yellow: "#ffc42b",
        blue: {
          DEFAULT: "#0d0662",
          light: "#34499b",
        },
        cyan: {
          DEFAULT: "#00bbd1",
          light: "#92dce5",
        },
        pink: "#e60d76",
        red: "#ec000b",
        green: "#04d162",
        black: {
          dark: "#111110",
          DEFAULT: "#292626",
          light: "#353232",
        },
        stone: {
          DEFAULT: "#EEE9E6",
          dark: "#E8E0DB",
        },
      },
      screens: {
        // "3xl": "1792px",
      },
      aspectRatio: {
        "4/3": "4 / 3",
        "3/4": "3 / 4",
        "3/2": "3 / 2",
        "2/3": "2 / 3",
        "8/10": "8 / 10",
        "16/9": "16 / 9",
        "16/11": "16 / 11",
        "16/12": "16 / 12",
        "2.35/1": "2.35/1",
        square: "1 / 1",
      },
      lineClamp: {
        7: "7",
        8: "8",
        9: "9",
        10: "10",
      },
      fontFamily: {
        sans: ["Work Sans", ...defaultTheme.fontFamily.sans],
        serif: ["Tiempos Headline", ...defaultTheme.fontFamily.serif],
      },
    },
    container: {
      center: true,
      padding: "1.5rem",
    },
  },
  plugins: [
    require("@tailwindcss/forms")({
      strategy: "class",
    }),
    require("@tailwindcss/typography"),
    require("tailwindcss-easing"),
  ],
} satisfies Config;

export default config;
