import Button from "@/components/Button";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

const Heading = ({
  children,
  size = "lg",
  element = "h2",
  alignment = "center",
  target = null,
  buttonTheme = "red",
}) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  const Element = element;

  return (
    <div ref={ref} className={clsx("Heading", "container")}>
      <div className={clsx("grid grid-cols-24 items-center lg:gap-8")}>
        <Element
          className={clsx("", "transition duration-1000 ease-out", {
            "translate-y-2 opacity-0": !inView,
            "translate-y-0 opacity-100": inView,
            "col-span-24 mx-auto max-w-4xl text-center": alignment === "center",
            "col-span-24 text-left lg:col-span-18": alignment === "left",
            "heading-2xl": size === "2xl",
            "heading-xl": size === "xl",
            "heading-lg": size === "lg",
            "heading-md": size === "md",
            "heading-sm": size === "sm",
          })}
          dangerouslySetInnerHTML={{ __html: children }}
        ></Element>
        {!!target && !!target.url && (
          <div
            className={clsx("hidden lg:block", {
              "col-span-24 lg:col-span-6 text-right": alignment === "left",
              "col-span-24 mt-12 flex justify-center": alignment === "center",
            })}
          >
            <Button target={target} theme={buttonTheme} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;
