import EntryCard from "@/components/EntryCard";
import clsx from "clsx";

const EntryGrid = ({
  entries,
  size = "lg",
  priority = false,
}: {
  entries: any[];
  size?: "lg" | "sm";
  priority?: boolean;
}) => {
  // Create a sizes attribute based on grid size
  let sizes =
    size === "lg"
      ? "(min-width: 768px) 50vw, 100vw"
      : "(min-width: 1024px) 33.333vw, (min-width: 768px) 50vw, 100vw";

  return (
    <div className={clsx("EntryGrid", "container")}>
      <div
        className={clsx("grid grid-cols-24 gapy-y-8 lg:gap-y-16 md:gap-x-8")}
      >
        {entries.map((entry, index) => {
          if (!entry) return null;

          let image = !!entry.image ? entry.image?.[0] : null;

          // Determine the card's priorty
          const cardPriority =
            priority && (size === "lg" ? index < 2 : index < 3);

          return (
            <div
              key={entry.id}
              className={clsx("col-span-24", {
                "md:col-span-12": size === "lg",
                "md:col-span-12 lg:col-span-8": size === "sm",
              })}
            >
              <EntryCard
                image={image}
                heading={entry.title}
                subHeading={entry.subHeading}
                href={`/${entry.uri}`}
                summary={entry.summary}
                sizes={sizes}
                priority={cardPriority}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EntryGrid;
